import React, { useState } from 'react';
import axios from 'axios';
import AsyncSelect from 'react-select/async';
import { Container, Form, Button, Row, Col, Alert, Tabs, Tab } from 'react-bootstrap';
import logo from './logo.png';
import refreshIcon from './refresh-icon.png'; 

// Define custom styles for AsyncSelect
const customStyles = {
  option: (provided) => ({
    ...provided,
    color: 'black',  // Change text color
  }),
  singleValue: (provided) => ({
    ...provided,
    color: 'black',  // Change text color
  }),
};

function App() {
  const [fighter1, setFighter1] = useState('');
  const [fighter2, setFighter2] = useState('');
  const [prediction, setPrediction] = useState('');
  const [error, setError] = useState(false);
  const [explanation, setExplanation] = useState('');
  const [combinedData, setCombinedData] = useState({});
  const [customPrompt, setCustomPrompt] = useState('');
  const [analysisResponse, setAnalysisResponse] = useState('');
  const [activeTab, setActiveTab] = useState('explain'); // New state for tab selection
  const [isLoading, setIsLoading] = useState(false);

  // Tab switch handler
  const handleTabSelect = (key) => {
    setActiveTab(key);
  };

  const typeWriterEffect = (text, interval = 50) => {
    setExplanation(''); // Reset the explanation
    let i = -1;
    const timer = setInterval(() => {
      if (i <= text.length) {
        setExplanation(prev => prev + text.charAt(i));
        i++;
      } else {
        clearInterval(timer);
      }
    }, interval);
  };      

  const loadOptions = async (inputValue) => {
    try {
      // Convert the input to lowercase before sending the request
      const response = await axios.get(`https://ufcai-d96b7bcb5546.herokuapp.com/search-fighters?query=${inputValue.toLowerCase()}`);
      return response.data.map(fighter => ({ label: fighter, value: fighter }));
    } catch (error) {
      console.error('Error fetching fighter names:', error);
      return [];
    }
  };  
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(false);
    setExplanation('');
    setIsLoading(false);
    try {
      const response = await axios.post('https://ufcai-d96b7bcb5546.herokuapp.com/predict', { fighter1, fighter2 });
      // Update this line to match the key in your Flask response
      setPrediction(response.data.final_result || 'No prediction available'); // Assuming the key is 'result3'
      setCombinedData(response.data.combined_data || {});
    } catch (error) {
      console.error('Error making prediction:', error);
      setError(true);
      setPrediction('Error in making prediction. Please try again.');
    }
  };  

  const handleAnalyze = async () => {
    setIsLoading(true);
    try {
        const requestData = {
            prompt: customPrompt,
            combined_data: combinedData,
            fighter1: fighter1,
            fighter2: fighter2,
            final_result: prediction
        };
      
        const response = await axios.post('https://ufcai-d96b7bcb5546.herokuapp.com/analyze', requestData);
        setAnalysisResponse(response.data.response);
    } catch (error) {
        console.error('Error fetching analysis:', error);
        setAnalysisResponse('Error in fetching analysis. Please try again.');
    }
    setIsLoading(false);
  };

  const handleExplain = async () => {
    setIsLoading(true);
    setExplanation(''); // Reset the explanation before fetching new one
    try {
        const requestData = {
            combined_data: combinedData,
            final_result: prediction,
            fighter1: fighter1, // Assuming you have a state variable for fighter1
            fighter2: fighter2  // Assuming you have a state variable for fighter2
        };
  
        const response = await axios.post('https://ufcai-d96b7bcb5546.herokuapp.com/explain', requestData);
        typeWriterEffect(response.data.explanation); 
    } catch (error) {
        console.error('Error fetching explanation:', error);
        setExplanation('Error in fetching explanation. Please try again.');
    }
    setIsLoading(false);
  };  

  const getAlertVariant = () => {
    if (error) {
      return 'danger';
    } else if (prediction.toLowerCase().includes('loss')) {
      return 'danger';
    } else {
      return 'success';
    }
  };

  // Update your Odds API widget source URL here
  const oddsWidgetSrc = `https://widget.the-odds-api.com/v1/sports/mma_mixed_martial_arts/events/?accessKey=wk_1ac5574c363a6bacb938f4d8c4f15043&bookmakerKeys=draftkings&oddsFormat=american&markets=h2h`;


  return (
    <Container className="mt-0  full-width-container scrollable-container">
      <h1 className="text-center mb-4">UFC AI</h1>
      <Form onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Col>
            <AsyncSelect
              cacheOptions
              loadOptions={loadOptions}
              defaultOptions
              onChange={(selectedOption) => setFighter1(selectedOption ? selectedOption.value : '')}
              placeholder="Fighter 1 Name"
              styles={customStyles}
              isClearable
              isSearchable
            />
          </Col>
          <Col>
            <AsyncSelect
              cacheOptions
              loadOptions={loadOptions}
              defaultOptions
              onChange={(selectedOption) => setFighter2(selectedOption ? selectedOption.value : '')}
              placeholder="Fighter 2 Name"
              styles={customStyles}
              isClearable
              isSearchable
            />
          </Col>
        </Row>
        <Row>
          <Col className="text-center">
            <Button variant="primary" type="submit" className="mt-3">
              Predict Outcome
            </Button>
          </Col>
        </Row>
      </Form>
  
      {prediction && (
        <>
          <Alert variant={getAlertVariant()} className="mt-4 text-center">
            {prediction}
          </Alert>
  
            {isLoading ? (
                <div className="matrix-loading">
                  <img src={logo} alt="Loading logo" style={{ width: '50px', height: '50px' }} /> {/* Adjust size as needed */}
                  Loading...
                </div>
              ) : (
                <>
                  {explanation && (
                    <>
                    <Alert variant="info" className="mt-4 typewriter">
                      {explanation}
                    </Alert>
                    <div className="text-center">
                      {/* Refresh Button with Icon */}
                      <Button
                        variant="info"
                        onClick={handleExplain}
                        className="mt-3"
                      >
                        <img src={refreshIcon} alt="Refresh" style={{ width: '20px', height: '20px' }} />
                      </Button>
                    </div>
                  </>
                  )}
                  {!explanation && (
                    <div className="text-center">
                      <Button variant="info" onClick={handleExplain} className="mt-3">
                        Explain To Me
                      </Button>
                    </div>
                  )}
                </>
              )}
        </>
      )}
  
      {/* Odds Widget */}
      <div className="mt-4 text-center">
        <iframe
          src={oddsWidgetSrc}
          style={{ width: "100%", height: "500px", border: "1px solid grey" }}
          title="Odds Widget"
        ></iframe>
      </div>
    </Container>
  );    
}

export default App;